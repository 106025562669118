import React, { useContext } from 'react'
import { Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'
import { SplitContext } from '@splitsoftware/splitio-react'

import {
  FF_EXPT_COST_COMPARE_FUNERAL_PLANS_LP_ENABLED,
  FUNERAL_PLAN_EMAIL,
  FUNERAL_PLAN_TELEPHONE_NUMBER,
  FUNERAL_PLAN_TELEPHONE_NUMBER_EXPERIMENT,
} from 'config'
import { PRODUCTS } from 'lib/products/constants'
import { getStructuredBreadCrumbs } from 'lib/json-ld/getStructuredBreadCrumbs'
import DefaultLayout from 'layouts/DefaultLayout'
import JSONLD from 'components/JSONLD'

import Content from 'views/cremation/public/FuneralPlans/Content'
import { FORMATTED_CREMATION_FUNERAL_PLAN_FEE } from 'views/cremation/shared/constants'
import { useExperiment } from 'lib/hooks/useExperiment'

import PATHS from 'paths'

const pageTitle = `Funeral Plans for ${FORMATTED_CREMATION_FUNERAL_PLAN_FEE} - Arrange today with Farewill Funerals`
const pageDescription = `Get peace of mind with a prepaid direct cremation funeral plan for ${FORMATTED_CREMATION_FUNERAL_PLAN_FEE}. No fuss, no hidden extras.`

const FuneralOverview = (): React.ReactElement | null => {
  // ExptCostCompareFuneralPlansLP - Refactor this component after experiment is done
  const treatment = useExperiment('ExptCostCompareFuneralPlansLP')
  const { isReady } = useContext(SplitContext)
  const isExperiment =
    treatment === 'experiment_variant' &&
    FF_EXPT_COST_COMPARE_FUNERAL_PLANS_LP_ENABLED

  if (!isReady) return null

  return (
    <Wrapper background={COLOR.WHITE}>
      <JSONLD data={[getStructuredBreadCrumbs({})]} />
      <DefaultLayout
        title={pageTitle}
        description={pageDescription}
        product={
          isExperiment
            ? PRODUCTS.FUNERAL_PLAN_EXPERIMENT
            : PRODUCTS.FUNERAL_PLAN
        }
        headerColor={isExperiment ? COLOR.ACCENT.PRIMARY_60 : COLOR.WHITE}
        headerType="compact"
        footerType="compact"
        email={FUNERAL_PLAN_EMAIL}
        footerShowFuneralPlansLegalInformation
        telephoneNumber={
          isExperiment
            ? FUNERAL_PLAN_TELEPHONE_NUMBER_EXPERIMENT
            : FUNERAL_PLAN_TELEPHONE_NUMBER
        }
        usePhoneNumberTracking={false}
        headerFarewillLogoLink={PATHS.CREMATION.FUNERAL_OVERVIEW}
        meta={[
          {
            name: 'robots',
            content: 'noindex,follow',
          },
        ]}
      >
        <Content />
      </DefaultLayout>
    </Wrapper>
  )
}

export default FuneralOverview
